(function() {
  var nav = document.getElementById("nav");
  var navTop = nav.offsetTop;

  window.addEventListener("scroll", function() {
    if (this.window.pageYOffset >= navTop) {
      nav.classList.add("sticky");
    } else {
      nav.classList.remove("sticky");
    }
  });
})();
